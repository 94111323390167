<template>
  <van-tabs v-model:active="active" :before-change="beforeChange" @change="onTabChange" sticky offset-top="10.13333vw">
    <van-tab title="信息" name="msg">
      <van-form @submit="onSubmit">
        <van-cell-group title="驾驶员信息" icon="friends-o" inset>
          <van-field
              v-model="id"
              name="id"
              v-show="false"
          ></van-field>
          <van-field
              v-model="this.$data['visitor.id']"
              name="visitor.id"
              v-show="false"
          ></van-field>
          <van-field
              v-model="code"
              name="code"
              label="预约编号"
              placeholder="自动生成"
              :readonly="true"
          ></van-field>
          <van-field
              v-model="name"
              name="name"
              label="姓名"
              placeholder="姓名"
              :readonly="true"
              required
              :rules="[{ required: true, message: '请填写姓名' }]"
          ></van-field>
          <Picker
              name="genderDic.id"
              label="性别"
              displayField="name"
              valueField="id"
              :required=false
              :readonly=true
              v-model:value="this.$data['genderDic.id']"
              :defConfig="{
                  portName:'touch.system.Dic.getChildDicsByCode',
                  data: {'code': 'BASE_BUSINESS_GENDER'},
                }"
          />

          <van-field
              v-model="tel"
              name="tel"
              label="电话号码"
              :readonly="true"
              placeholder="电话号码"
          ></van-field>
          <van-field
              v-model="unit"
              name="unit"
              label="所属单位"
              :readonly="true"
              placeholder="所属单位"
          ></van-field>

          <van-field
              v-model="originArea"
              name="originArea"
              label="来源地区"
              required
              :rules="[{ required: true, message: '请填来源地区' }]"
              placeholder="来源地区"
          ></van-field>

        </van-cell-group>

        <van-cell-group title="车辆信息" icon="friends-o" inset>
          <Picker
              name="carNum"
              label="车牌号"
              placeholder="请选择车辆"
              displayField="carNum"
              valueField="carNum"
              :required=true
              v-model:value="this.$data['carNum']"
              :defConfig="{
                portName:'osp.visitor.VisitorCarEntity.getVisitorCars',
                data: {'visitor.id': this.$data['visitor.id'], 'typeDicCodes': ['VISITOR_CAR_TYPE_TRUCK', 'VISITOR_CAR_TYPE_HAZARDOUS_TRUCK', 'VISITOR_CAR_TYPE_CHEMICAL_TRUCK']},
              }"
              :rules="[{ required: true, message: '请选择车辆' }]"
              :canSearch=false
              :defSelectValCallback="function(raw) {
              if (raw.default){
                return true;
              }
            }"
              @onConfirm="onCarConfirm"
          />
          <van-field
              readonly
              name="brand"
              v-model="brand"
              label="车辆型号"
          ></van-field>
          <Picker
              name="typeDic.id"
              label="车辆类型"
              :required=true
              :readonly=true
              v-model:value="this.$data['typeDic.id']"
              :defConfig="{
              portName:'system.foundation.Dic.getChildDicsByCode',
              data: {'code': 'VISITOR_CAR_TYPE'},
            }"
          />

          <Picker
              name="purpose.id"
              label="目的"
              placeholder="请选择目的"
              displayField="name"
              valueField="id"
              select-first
              :required=true
              :readonly="purposeReadOnly"
              v-model:value="this.$data['purpose.id']"
              :defConfig="{
                    portName:'system.foundation.Dic.getChildDicsByCode',
              data: {'code': 'VEHICLE_PURPOSE',
              'needCodes': ['VEHICLE_PURPOSE_LOAD', 'VEHICLE_PURPOSE_UNLOAD']},
            }"
              :rules="[{ required: true, message: '请选择目的' }]"
              :canSearch=false
              ref="purposePicker"
          />

          <DatePicker
              name="planDate"
              label="预约入园日期"
              v-model:value="planDate"
              :required=true
              :min-date="minDate"
              :rules="[{ required: true, message: '请选择访问时间' }]"
          />

          <DatetimePicker
              name="startTime"
              v-model:value="startTime"
              max-hour="18"
              min-hour="02"
              type="time"
              :required=true
              label="预计入园时间"
              :label-width= 110
          />
          <van-field
              v-show="true"
              type="textarea"
              v-model="remark"
              name="remark"
              label="备注"
          ></van-field>
          <van-field name="drivingLicenseFront" label="行驶证(正)" :required=true>
            <template #input>
              <van-uploader name="drivingLicenseFront" v-model="drivingLicenseFront"
                            :before-read="beforeRead"
                            :after-read="afterRead"
                            max-count="1"></van-uploader>
            </template>
          </van-field>
          <van-field name="drivingLicenseBack" label="行驶证(副)" :required=true>
            <template #input>
              <van-uploader name="drivingLicenseBack" v-model="drivingLicenseBack"
                            :before-read="beforeRead"
                            :after-read="afterRead"
                            max-count="1"></van-uploader>
            </template>
          </van-field>

          <van-field name="operationAttach" label="道路运输证" :required=true>
            <template #input>
              <van-uploader name="operationAttach" v-model="operationAttach"
                            :before-read="beforeRead"
                            :after-read="afterRead"
                            max-count="1"></van-uploader>
            </template>
          </van-field>

          <van-field name="report" label="报告单附件" v-show="true">
            <template #input>
              <van-uploader name="report" v-model="report"
                            :before-read="beforeRead"
                            :after-read="afterRead"
                            max-count="1"></van-uploader>
            </template>
          </van-field>

        </van-cell-group>

        <div style="margin: 4.26667vw">
          <van-button round block type="primary" native-type="submit" :disabled="btnDisabled">
            下一步
          </van-button>
        </div>
      </van-form>
    </van-tab>

    <van-tab title="物料" name="material" :beforeChange="beforeChange">
      <ChemicalReserveMaterial
          :reserveID="this.id"
          :purposeCode="this.purposeCode"
          :is-purchase="isPurchase"
          :back="pageChange"
      />
    </van-tab>
  </van-tabs>


</template>

<script>

import Picker from "../../components/Picker";
import DatetimePicker from "../../components/DatetimePicker";
import DatePicker from "../../components/DatePicker";
import "vue3-video-play/dist/style.css";
import {videoPlay} from "vue3-video-play";
import {reactive} from "vue";
import _ from "lodash";
import ChemicalReserveMaterial from "./ChemicalReserveMaterial";
import {Toast} from "vant";
import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'
import globals from '../../public/js/global'


export default {
  name: "CommonVehicleReserve",
  components: {Picker, DatePicker, DatetimePicker, ChemicalReserveMaterial},
  created() {
    this.loadData();
  },
  data() {
    return {
      active: "msg",
      showTab: false,
      id: '',
      code: '',
      name: '',
      tel: '',
      unit: '',
      originArea: '',
      'genderDic.id': '',
      carNum: '',
      brand: '',
      'typeDic.id': '',
      remark: '',
      'purpose.id': '',
      'visitor.id': '',
      planDate: new Date().pattern('yyyy-MM-dd').toString(),
      startTime: '10:00',
      endTime: '12:00',
      minDate: new Date(),
      drivingLicenseFront: [],
      drivingLicenseBack: [],
      operationAttach: [],
      report: [],
      btnDisabled: false,
      options: {},
      showDialog: true,
      videoShow: true,
      purposeReadOnly: false,
      isPurchase: true,
      purposeCode: '',
      isLoad: true,
      loadType:'mp4',
      playerConfig:{}, // 播放器配置
    };
  },
  mounted() {
    //this.checkPass();
  },
  beforeUnmount() {
    if (_.isFunction(globals.mp.destory)) {
      globals.mp.destory()
    }
  },
  watch: {
    id: function (val) {
      if (!_.isEmpty(val)) {
        this.showTab = true;
      } else {
        this.showTab = false;
        this.purposeReadOnly = false;
      }
    },
  },
  methods: {
    loadData() {
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        me.btnDisabled = true;
        me.showDialog = false;
        var option = {
          portName: 'osp.car.VehicleReserve.getVehicleReserve',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
              var planDate = entity.planDate;
              if (!_.isEmpty(planDate)) {
                planDate = planDate.replace(/-/g, '/');
              }
              _data.planDate = _.isEmpty(planDate) ? "" : new Date(planDate).pattern('yyyy-MM-dd').toString();
              var statusDicCode = entity['statusDic.code'];
              me.purposeReadOnly = (statusDicCode=='VISITOR_RESERVE_STATUS_APPROVING'|| statusDicCode=='VISITOR_RESERVE_STATUS_PASS')
              me.btnDisabled = (statusDicCode=='VISITOR_RESERVE_STATUS_APPROVING'|| statusDicCode=='VISITOR_RESERVE_STATUS_PASS');
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        var visitor = this.$sapi.storage.getItemJson('visitor');
        this.$data['visitor.id'] = visitor.id;
        this.name = visitor.name;
        this.tel = visitor.tel;
        this.unit = visitor.unit;
        this['genderDic.id'] = visitor['genderDic.id'];
      }
    },

    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true);
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onCarConfirm(value) {
      this.brand = value.brand;
      this['typeDic.id'] = value['typeDic.id'];

      var drivingLicenseFront1 = value['drivingLicenseFront'];
      var drivingLicenseBack1 = value['drivingLicenseBack'];
      var operationAttach1 = value['operationAttach'];
      if (drivingLicenseFront1.length !== 0) {
        var option = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseFront1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseFront = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option);
      }
      if (drivingLicenseBack1.length !== 0) {
        var option1 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: drivingLicenseBack1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.drivingLicenseBack = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option1);
      }
      if (operationAttach1.length !== 0) {
        var option2 = {
          portName: 'system.foundation.Attach.copyImage',
          data: {fileID: operationAttach1[0]['fileID']},
          needLoading: false,
          successCallback: (data) => {
            this.operationAttach = [data.data['fileModel']];
          }
        }
        this.$sapi.callPort(option2);
      }
    },
    onTabChange(index) {
      if (index === "msg") {
        return;
      }
      var raw = this.$refs.purposePicker.getSelectedRaw();
      this.isPurchase = (raw.code == 'VEHICLE_PURPOSE_UNLOAD');
      this.isLoad = (raw.code == 'VEHICLE_PURPOSE_LOAD');
      this.purposeCode = raw.code;
    },
    onSubmit(values) {
      values['id'] = this.id;
      values['visitor.id'] = this['visitor.id'];
      if (this.drivingLicenseFront.length == 0
          || this.drivingLicenseBack.length == 0) {
        Toast("请上传行驶证照片");
        return;
      }
      this.drivingLicenseFront[0]['fileCode'] = 'front';
      this.drivingLicenseBack[0]['fileCode'] = 'back';
      values['visitor.id'] = this['visitor.id'];
      values['orderReserve'] = true;
      var me = this;
      var option = {
        portName: 'osp.car.VehicleReserve.saveVehicleReserve',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.id = data.data.id;
          this.active= "material";
          this.onTabChange("material");
        }
      };
      this.btnDisabled = true;
      try {
        this.$sapi.callPort(option);
      } finally {
        this.btnDisabled = false;
      }
    },
    onSubmitReserve() {
      var option = {
        portName: 'osp.car.VehicleReserve.submitVehicleReserve',
        data: {ids: [this.id]},
        needLoading: false,
        successCallback: (data) => {
          this.$notify({type: 'success', message: '提交成功'});
          this.purposeReadOnly = true;
        }
      };
      this.btnDisabled = true;
      try {
        this.$sapi.callPort(option);
      } finally {
        this.btnDisabled = false;
      }
    },
    pageChange(){
      this.active= "msg";
    },
    beforeChange() {
      var id = this.id;
      var beforeChange = false;
        if (!_.isEmpty(id)) {
          beforeChange = true;
        } else {
          beforeChange =  false;
        }
      return beforeChange;
    }
  }
}
</script>

<style scoped>

</style>