<template>
  <van-popup v-model:show="this.$props.show"
             :style="{ height: '80%' }"
             position="bottom"
             :close-on-click-overlay="false"
             :lazy-render='true'
             teleport="body"
  >
    <van-search
        :label="label"
        v-model="searchText"
        show-action
        placeholder="搜索"
        @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-picker
        show-toolbar
        title="物料"
        :columns="columns"
        :columns-field-names="{ text: 'maktx'}"
        @confirm="onConfirm"
        @cancel="this.$emit('update:show', false)"
        :default-index="index"
    />
  </van-popup>
</template>
<script>
import _ from "lodash";

export default {
  name: 'SearchOrderWin',

  components: {},

  props: {
    show: {
      default: false,
      type: Boolean
    },
    label: {
      default: "订单号",
      type: String
    },
    portName: {
      default: "",
      type: String
    },
    requestData: {
      default: null,
      type: Object
    }
  },

  emits: ['update:show', 'onConfirm'],

  setup() {
  },

  data() {
    return {
      text: "",
      index: 0,
      searchText: '',
      textValue: '',
      columns: [],
    };
  },

  computed: {},

  watch: {},

  created() {

  },

  mounted() {
    this.getColumns();
  },

  unmounted() {
  },

  methods: {
    getColumns() {
      this.columns.length = 0;
      var me = this;
      if (_.isEmpty(me.portName)) {
        return;
      }
      var option = {
        portName: me.portName,
        data: me.requestData == null ? {} : me.requestData,
        needLoading: false,
        successCallback: (data) => {
          var entities = data.entities;
          this.columns = entities;
        }
      };
      if (!_.isEmpty(this.searchText)) {
        option.data['code'] = this.searchText;
      } else {
        return;
      }
      this.$sapi.callPort(option);
    },
    onSearch() {
      this.getColumns();
    },

    onConfirm(value, index) {
      if (_.isEmpty(value)) {
        return;
      }
      this.$emit('update:show', false);
      this.$emit('onConfirm', value);
    }
  },
}
</script>

<style scoped>

</style>