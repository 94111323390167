<template>

  <van-popup v-model:show="this.$props.show"
             :style="{ height: '80%' }"
             position="bottom"
             teleport="body"
             closeable
             :close-on-click-overlay="false"
             :lazy-render='true'
             @click-close-icon="this.$emit('update:show', false);"
  >

    <van-form @submit="onConfirm">
      <van-cell-group title="" icon="friends-o" inset>
        <van-field
            v-model="item.id"
            name="id"
            v-show="false"
        ></van-field>
        <van-field
            v-model="item['reserve.id']"
            name="reserve.id"
            v-show="false"
        ></van-field>
        <van-field
            v-model="item.orderCode"
            v-show="true"
            name="orderCode"
            label="订单号"
            :readonly="true"
        />
        <van-field
            v-model="item['material.id']"
            name="material.id"
            label="物料名称"
            :readonly="true"
            required
            :rules="[{ required: true, message: '请选择物料' }]"
            @click="this.showSelectPicker= false"
        >
          <template #input>
            <div class="van-field__control">{{ item['material.name'] }}</div>
          </template>
        </van-field>
        <select-material
            v-model:show="showSelectPicker">
        </select-material>

        <van-field
            v-model="item.qty"
            name="qty"
            label="预约数量"
            type="number"
        >
        </van-field>
        <van-field
            v-model="item.orderQty"
            v-show="true"
            name="orderQty"
            label="订单数量"
            :readonly="true"
        />
        <van-field
            v-model="item.unit"
            name="unit"
            label="单位"
            :readonly="true"
        ></van-field>
        <van-field
            
            v-model="item['point.id']"
            name="point.id"
            v-show="false"
        ></van-field>
<!--        <Picker
            name="platform.id"
            label="站台"
            placeholder="请选择装卸点站台"
            valueField="platform.id"
            displayField="platform.name"
            :required=true
            v-model:value="item['platform.id']"
            :defConfig="this.$props.isLoad?
            {
            portName: 'osp.park.LoadMaterial.getLoadMaterials',
              data: {'material.id': item['material.id']}
            }: {     portName: 'osp.park.UnloadMaterial.getUnloadMaterials',
              data: {'material.id': item['material.id']}
            }"
            :rules="[{ required: true, message: '请选装卸点站台' }]"
            :canSearch=true
            @onConfirm="onPlatformConfirm"
            :onClick="beforePickPlatform"
        ></Picker>
        <van-field
            v-model="item.pointName"
            name="PointName"
            label="装卸点"
            :readonly="true"
        ></van-field>
        <van-field
            v-model="item.principalNames"
            name="principalNames"
            label="负责人"
            :readonly="true"
        ></van-field>
        <van-field
            v-model="item.pointPhone"
            name="pointPhone"
            label="手机号"
            :readonly="true"
        ></van-field>-->
        <van-field
            v-model="item.supplier"
            name="supplier"
            :label="this.$props.isLoad? '客户':'供应商'"
            :readonly="true"
        ></van-field>
        <van-field
            v-model="item.supplierNo"
            name="supplierNo"
            :label="this.$props.isLoad? '客户编号':'供应商编号'"
            :readonly="true"
        ></van-field>
        <div style="margin: 4.26667vw;
">
          <van-button round block type="primary" native-type="submit">
            确定
          </van-button>
        </div>
      </van-cell-group>
    </van-form>
  </van-popup>

</template>

<script>
import _ from "lodash";
import Picker from "../../components/Picker";
import SelectMaterial from "./SelectMaterial";
import {Toast} from "vant";

export default {
  name: "EditOrderMaterialPopup",

  components: {SelectMaterial},

  props: {
    show: {
      default: false,
      type: Boolean
    },
    requestData: {
      default: null,
      type: Object
    },
    isLoad: {
      default: false,
      type: Boolean
    }
  },

  emits: ['update:show', 'onConfirm'],

  setup() {
  },

  data() {
    return {
      item: {
        id: '',
        'reserve.id': '',
        'material.id': '',
        'material.name': '',
        qty: '',
        orderCode: '',
        orderQty: 0,
        unit: 'kg',
        // 'platform.id': '',
        // pointName: '',
        // principalNames: '',
        // pointPhone: '',
        supplier: '',
        supplierNo: '',
        'point.id': ''
      },
      showSelectPicker: false
    };
  },

  computed: {},

  watch: {},

  created() {

  },

  mounted() {
  },

  unmounted() {
  },

  methods: {
    onConfirm(value, index) {
      if (_.isEmpty(value)) {
        return;
      }
      this.$emit('update:show', false);
      this.$emit('onConfirm', value);
    },
    // selectMaterial(value, index) {
    //   this.item = {
    //     id: this.item.id,
    //     'reserve.id': this.item["reserve.id"],
    //     'material.id': value.id,
    //     'material.name': value.name,
    //     qty: 0,
    //     unit: value.unit,
    //     'point.id': '',
    //     // pointName: '',
    //     // principalNames: '',
    //     // pointPhone: '',
    //     supplier: this.supplier,
    //     supplierNo: this.supplierNo
    //   }
    // },
    // beforePickPlatform() {
    //   var id = this.item["material.id"];
    //   if (_.isEmpty(id)) {
    //     Toast('请先选择物料');
    //     return false;
    //   } else {
    //     return true
    //   }
    // },
    /*onPlatformConfirm(value) {
      var stevedorePoint = value['platform.stevedorePoint.name'];
      var principalNames = value['principalNames'];
      var phone = value['platform.stevedorePoint.phone'];
      this.item.pointName = stevedorePoint;
      this.item.principalNames = principalNames;
      this.item.pointPhone = phone;
    },*/
    // onSupplierConfirm(value) {
    //   this.item.supplierNo = value.code;
    // }
  },
}

</script>

<style scoped>

</style>